import React from "react";
import PageWrapper from "../components/PageWrapper";
import Testimonial from "../sections/index/indexreviews.js";
import Contentchin from "../sections/chinfiller/chinfillercontent.js";
import GalleryChin from "../sections/chin/chingallery.js";
import ChinFaq from "../sections/chinfiller/chinfillerfaq.js";
import Head from "../components/head";
import Award from "../components/NewToxane.js";
import Trend from '../components/trends.js';
import Clinic from '../components/NewFind.js'; 
import Saveface from '../components/save.js';
import HeroComponent from "../components/ImageHero.js";
import imgVideo from "../assets/image/jpeg/chinjawfillers.jpg";
import Glance from "../components/glance.js";
import { Container, Row, Col } from "react-bootstrap";
import { Box, Section } from "../components/Core";

const Chin = () => {
  return (
    <>
    <Head title="Chin Filler Treatment London" image="/images/dermamina-home.jpg"  description="Experts in Chin Filler treatments London" keywords="chin filler treatment, chin augmentation, chin enhancement, chin fillers, facial contouring, dermal fillers, chin volume restoration, non-surgical chin augmentation, chin filler procedure, cosmetic chin fillers"/>
<PageWrapper headerDark>
<HeroComponent
    itext='<span style="color: #1a1a1a;">Chin </span> <span style="color: #00aec7;">Filler</span>'
    secondText="Profile balance with the Chin Filler Treatment"
    videoAlt="Chin Filler"
    imgVideo={imgVideo} // Specify your image URL here
  />

<Section py={0} className="pt-lg-5">
    <Container >
      <Row>
        <Col lg="12" xs="12" data-aos="fade-up"
              data-aos-duration="500"
              data-aos-once="false"
              data-aos-delay="100">
  <Glance  best="1 treatment"
  bestalt="best Chin Filler"
  resultstext="12 - 18 months"
  resultsalt="Chin Filler results"
  costdata="From £340"
  costalt="Chin Filler Cost"
  timedata="10 - 15 Minutes"
  timealt="Chin Filler Duration Time"
  workdata="Immediately"
  workalt="Chin Filler downtime work"
  paindata="None / Mild"
  painalt="Chin Filler Pain"/>
 </Col>
  </Row>
  </Container>
  </Section>
<GalleryChin />
<Award />
<Contentchin />
<ChinFaq /> 
<Testimonial />       
<Clinic /> 
<Saveface />
<Trend />
</PageWrapper>

    </>
  );
};
export default Chin;
